import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FormProvider, useForm } from 'react-hook-form'

import { actionCreators as providersFilterActions } from 'store/modules/ProvidersFilter'
import { applyFilters, trackFilterUsage } from 'helpers/filters'
import { formatDate } from 'helpers/date'
import { setPreselectedFormData } from 'hooks/preselectedFormData'

import FormattedMessage from 'components/FormattedMessage'
import Hint from 'components/Hint'
import Spinner from 'components/Spinner'

import FilterButtonWithOverlay from './components/FilterButtonWithOverlay'

import './AvailabilityFilter.less'

const DateTimerangePicker = dynamic(() => import('components/DateTimerangePicker'), {
  ssr: false,
  loading: () => <Spinner />,
})

const mapStateToProps = (state) => ({
  bookingDate: state.providersFilter.filters.bookingDate,
  cityFilter: state.providersFilter.filters.cityFilter,
  endTime: state.providersFilter.filters.endTime,
  guestCount: state.providersFilter.filters.guestCount,
  startTime: state.providersFilter.filters.startTime,
})

const mapDispatchToProps = {
  setProvidersFilter: providersFilterActions.setProvidersFilter,
}

const AvailabilityFilter = (props) => {
  const {
    bookingDate,
    cityFilter,
    endTime,
    guestCount,
    setProvidersFilter,
    startTime,
  } = props

  const form = useForm({ mode: 'onChange' })
  const router = useRouter()

  const applyFiltersHandler = async () => {
    const isValid = await form.trigger()

    if (!isValid) { return true }

    const {
      requestedDates,
      timesStartsAt,
      timesEndsAt,
    } = form.getValues()

    if (!requestedDates.isValid()) { return true }

    const filters = {
      bookingDate: requestedDates.format('YYYY-MM-DD'),
      startTime: timesStartsAt,
      endTime: timesEndsAt,
    }

    trackFilterUsage(filters)
    setProvidersFilter(filters, () => applyFilters(router))
    setPreselectedFormData({ requestedDates: [requestedDates], timesStartsAt, timesEndsAt })
  }

  const resetFiltersHandler = () => {
    setProvidersFilter({
      bookingDate: '',
      startTime: '',
      endTime: '',
    }, () => applyFilters(router))

    setPreselectedFormData({
      requestedDates: [],
      timesStartsAt: '',
      timesEndsAt: '',
    })
  }

  const requiredFieldsMissing = !cityFilter || !guestCount
  const initialDateTimerangePickerData = {
    requestedDates: [bookingDate],
    timesStartsAt: startTime,
    timesEndsAt: endTime,
  }

  const shownDate = startTime && endTime && bookingDate && `${formatDate(bookingDate)} ${startTime}-${endTime}`

  const buttons = [{
    placeholder: <FormattedMessage id="providersFilter.availability.label" />,
    value: shownDate,
  }]

  return (
    <FilterButtonWithOverlay
      applyHandler={applyFiltersHandler}
      resetHandler={resetFiltersHandler}
      className="provider-filter__availability-filter"
      buttons={buttons}
    >
      <FormProvider {...form}>
        <DateTimerangePicker initialData={initialDateTimerangePickerData} />

        {requiredFieldsMissing && (
          <Hint variant="notice">
            <FormattedMessage id="providersFilter.availability.requiredFieldsMissing" />
          </Hint>
        )}
      </FormProvider>
    </FilterButtonWithOverlay>
  )
}

AvailabilityFilter.propTypes = {
  bookingDate: PropTypes.string,
  cityFilter: PropTypes.string,
  endTime: PropTypes.string,
  guestCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setProvidersFilter: PropTypes.func.isRequired,
  startTime: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityFilter)
