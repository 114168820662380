import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import './index.less'

const ButtonGroup = React.forwardRef<HTMLDivElement, PropsWithChildren<ButtonGroupProps>>(
  ({ children, className, ...restProps }, ref) => (
    <div
      ref={ref}
      className={classNames('button__group', className)}
      {...restProps}
    >
      {children}
    </div>
  ),
)

interface ButtonGroupProps {
  className?: string
  onClick?: () => void
}

export default ButtonGroup
