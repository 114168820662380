import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import classNames from 'classnames'

import FormattedMessage from 'components/FormattedMessage'
import HighlightedText from 'components/HighlightedText'

import List from './List'

import './index.less'

let filterTimeout

const ChooseList = (props) => {
  const {
    filterable,
    filterInputProps: {
      onChange,
      ...filterInputProps
    },
    filterCallback,
    highlights = (query) => [query],
    initialValue,
    multiple,
    label,
    list,
    prependList,
    className,
    ...restProps
  } = props

  const [selectedItems, setSelectedItems] = useState(Array.isArray(initialValue) ? initialValue : [initialValue])
  const [filterQuery, setFilterQuery] = useState('')

  const selectCallback = useCallback((id) => {
    let result = []

    if (!selectedItems.includes(id)) {
      result = [id]
    }

    if (multiple) {
      result = [...selectedItems]

      if (result.includes(id)) {
        result = result.filter(item => item !== id)
      } else {
        result.push(id)
      }
    }

    setSelectedItems(result)
    props.selectCallback(multiple ? result : result[0])
  }, [selectedItems, setSelectedItems, multiple, props.selectCallback])

  const inputHandler = useCallback((event) => {
    const target = event.target

    clearTimeout(filterTimeout)
    filterTimeout = setTimeout(() => {
      const enteredValue = target.value

      setFilterQuery(enteredValue)
      onChange && onChange(enteredValue, event)
    }, 200)
  }, [setFilterQuery, onChange])

  const listToRender = useMemo(() => {
    const defaultFilterCallback = item => item.text.toLowerCase().indexOf(filterQuery.toLowerCase()) > -1
    let listToRender = list

    if (filterCallback) {
      listToRender = list.filter(item => filterCallback(item, filterQuery, defaultFilterCallback))
    } else {
      listToRender = list.filter(defaultFilterCallback)
    }

    return listToRender
  }, [list, filterCallback, filterQuery])

  const PrependListComponent = prependList

  return (
    <div
      className={classNames(
        'choose-list',
        className,
        {
          'choose-list--has-filter': filterable,
        },
      )}
    >
      {label && (
        <label className="choose-list__label">{label}</label>
      )}

      {filterable && (
        <div className="choose-list__filter">
          <Input
            onChange={inputHandler}
            {...{
              ...filterInputProps,
              className: classNames([filterInputProps.className, 'ant-input--with-border-radius']),
            }}
          />
        </div>
      )}

      {PrependListComponent && (
        <PrependListComponent list={listToRender} filterQuery={filterQuery} />
      )}

      {listToRender.length === 0 && (
        <div className="choose-list__no-result">
          <FormattedMessage id="chooseList.nothingFound" />
        </div>
      )}

      {listToRender.length > 0 && (
        <List
          list={listToRender}
          onSelectCallback={selectCallback}
          selectedItems={selectedItems}
          renderItem={(item) => (
            <HighlightedText text={item.text} highlights={highlights(filterQuery)} />
          )}
          {...restProps}
        />
      )}
    </div>
  )
}

ChooseList.defaultProps = {
  filterInputProps: {},
  selectCallback: () => {},
}

ChooseList.propTypes = {
  className: PropTypes.string,
  filterable: PropTypes.bool,
  filterInputProps: PropTypes.object,
  filterCallback: PropTypes.func,
  highlights: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  prependList: PropTypes.func,
  selectCallback: PropTypes.func,
  multiple: PropTypes.bool,
  label: PropTypes.node,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
}

export default ChooseList
