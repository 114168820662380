import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Badge from 'components/Badge'
import ButtonGroup from 'components/Button/Group'
import Button from 'components/Button'
import Icon from 'components/Icon'

import useKeyboard from 'hooks/keyboard'

import '../../shared.less'
import './index.less'

const FilterButtonWithOverlay = (props) => {
  const {
    buttons,
    content,
    children,
    className,
    applyHandler,
    applyButtonProps,
    resetHandler,
    resetButtonProps,
    onCloseCallback,
    onOpenCallback,
  } = props

  const [isOpen, setOpen] = useState(false)
  const overlayRef = useRef()
  const buttonGroupRef = useRef()

  const applyButtonHandler = () => {
    Promise.resolve(applyHandler())
      .then((result) => {
        if (result !== false) {
          closeOverlay()
        }
      })
  }

  const closeOverlay = () => {
    if (!isOpen) { return false }
    setOpen(false)
    onCloseCallback && onCloseCallback()
  }

  const openOverlay = () => {
    if (isOpen) { return false }
    setOpen(true)
    onOpenCallback && onOpenCallback()
  }

  const smallScreenScrollIntoView = () => {
    const overlayBottom = overlayRef.current?.getBoundingClientRect()?.bottom
    const filterUpperScrollSpace = 30

    if (overlayBottom && buttonGroupRef.current && overlayBottom > window.innerHeight) {
      const buttonGroupTop = buttonGroupRef.current.getBoundingClientRect().top + window.scrollY

      window.scrollTo(0, buttonGroupTop - filterUpperScrollSpace)
    }
  }

  useEffect(() => {
    const outsideClickHandler = event => {
      if (
        isOpen &&
        overlayRef.current &&
        !overlayRef.current.contains(event.target) &&
        buttonGroupRef.current &&
        !buttonGroupRef.current.contains(event.target)
      ) {
        closeOverlay()
      }
    }

    document.addEventListener('click', outsideClickHandler)

    return () => {
      document.removeEventListener('click', outsideClickHandler)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      window.requestAnimationFrame(smallScreenScrollIntoView)
    }
  }, [isOpen])

  useKeyboard('escape', closeOverlay)

  useKeyboard('enter', (key, event) => {
    if (isOpen) {
      applyButtonHandler()
    }
  })

  const showFooter = !!(applyHandler || resetHandler)

  return (
    <div
      className={classNames(
        'provider-filter__filter-button-with-overlay',
        className,
        {
          'provider-filter__filter-button-with-overlay--open': isOpen,
          'provider-filter__filter-button-with-overlay--no-footer': !showFooter,
        },
      )}
    >
      <ButtonGroup
        className="provider-filter__filter-button-with-overlay__button-group"
        onClick={openOverlay}
        ref={buttonGroupRef}
      >
        {buttons.map(({ value, badge, placeholder, ...restProps }, index) => (
          <Button
            key={index}
            type="default"
            className={classNames(
              'ant-btn--brand-font',
              'provider-filter__button',
              'provider-filter__filter-button-with-overlay__button-group__button',
              {
                'provider-filter__button--selected': !!value,
              },
            )}
            {...restProps}
          >
            {value && (
              <>
                <span className="provider-filter__filter-button-with-overlay__button-group__button__value">
                  {value}
                </span>

                {badge && (
                  <Badge>{badge}</Badge>
                )}
              </>
            )}

            {!value && placeholder}
            <Icon name="angle-down" />
          </Button>
        ))}
      </ButtonGroup>

      {isOpen && (
        <div ref={overlayRef} className="provider-filter__filter-button-with-overlay__overlay">
          <div className={classNames('provider-filter__filter-button-with-overlay__overlay__content', {
            'provider-filter__filter-button-with-overlay__overlay__content--with-footer': showFooter,
          })}>
            {content ? content(closeOverlay) : children}
          </div>

          {showFooter && (
            <div className="provider-filter__filter-button-with-overlay__overlay__footer">
              <Button
                type="primary"
                className={classNames(
                  'ant-btn--brand-font',
                  'ant-btn--with-border-radius-medium',
                  'provider-filter__filter-button-with-overlay__overlay__footer__button',
                )}
                translationKey="newProviderFilters.apply"
                onClick={applyButtonHandler}
                {...applyButtonProps}
              />

              {resetHandler && (
                <Button
                  type="link"
                  className={classNames(
                    'ant-btn--brand-font',
                    'ant-btn--with-border-radius-medium',
                    'provider-filter__filter-button-with-overlay__overlay__footer__button',
                    'provider-filter__filter-button-with-overlay__overlay__footer__button--reset',
                  )}
                  translationKey="newProviderFilters.reset"
                  onClick={() => {
                    resetHandler()
                    closeOverlay()
                  }}
                  {...resetButtonProps}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

FilterButtonWithOverlay.defaultProps = {
  applyButtonProps: {},
  resetButtonProps: {},
}

FilterButtonWithOverlay.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    badge: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    placeholder: PropTypes.node,
  })).isRequired,
  content: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  applyHandler: PropTypes.func,
  applyButtonProps: PropTypes.object,
  resetHandler: PropTypes.func,
  resetButtonProps: PropTypes.object,
  onCloseCallback: PropTypes.func,
  onOpenCallback: PropTypes.func,
}

export default FilterButtonWithOverlay
