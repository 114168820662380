import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form'

import FormattedMessage from 'components/FormattedMessage'
import { setPreselectedFormData } from 'hooks/preselectedFormData'
import FormattedPrice from 'components/FormattedPrice'
import FormItem from 'components/Form/Item'
import Input from 'components/Form/Input'

import { setProvidersFilter } from 'store/modules/ProvidersFilter'
import { applyFilters, trackFilterUsage } from 'helpers/filters'
import { greaterThanField, greaterThan } from 'helpers/validations'

import FilterButtonWithOverlay from '../components/FilterButtonWithOverlay'

import { RootState } from 'store/reducer'
import { IntlMessageKeys } from 'components/FormattedMessage/types'

import '../shared.less'
import './index.less'

const PricingFilter = () => {
  const form = useForm({ mode: 'onChange' })
  const intl = useIntl()
  const router = useRouter()
  const dispatch = useDispatch()
  const {
    isEventRoom,
    maxPrice,
    minPrice,
    currentUser,
  } = useSelector((state: RootState) => ({
    isEventRoom: state.providers.providerType === 'event_room',
    minPrice: state.providersFilter.filters.minPrice,
    maxPrice: state.providersFilter.filters.maxPrice,
    currentUser: state.currentUser,
  }), shallowEqual)

  const applyFiltersHandler = async () => {
    const isValid = await form.trigger()

    if (!isValid) { return false }

    const values = form.getValues()
    const clonedFilters = { ...values }

    trackFilterUsage(clonedFilters)

    return dispatch(setProvidersFilter(clonedFilters, (nextState) => {
      applyFilters(router)
      setPreselectedFormData({
        maxPrice: nextState.filters.maxPrice,
        minPrice: nextState.filters.minPrice,
      })
    }))
  }

  const resetFiltersHandler = () => {
    const emptyFilters = {
      maxPrice: '',
      minPrice: '',
    }

    dispatch(setProvidersFilter(emptyFilters, () => {
      applyFilters(router)
      setPreselectedFormData(emptyFilters)
    }))
  }

  const buttonTranslationKey = isEventRoom
    ? 'newProviderFilters.pricePerDay'
    : 'newProviderFilters.pricePerPerson'

  const currency = currentUser?.preferredPartner?.settings?.currency
  const priceFilterButton = [{
    placeholder: <FormattedMessage id={buttonTranslationKey} />,
    value: (!!maxPrice || !!minPrice) && (
      <>
        {maxPrice ? minPrice || '0' : minPrice && <FormattedPrice price={minPrice} asInteger />}
        {maxPrice ? ' - ' : ' + '}
        {maxPrice && <FormattedPrice price={maxPrice} asInteger />}
      </>
    ),
  }]

  const currencyTranslationKey:IntlMessageKeys = currency ? `common.currencies.${currency}` : 'common.currencySign'
  const currencySymbol = <FormattedMessage id={currencyTranslationKey} />

  return (
    <FilterButtonWithOverlay
      applyHandler={applyFiltersHandler}
      resetHandler={resetFiltersHandler}
      className="provider-filter__pricing-filter"
      buttons={priceFilterButton}
      onOpenCallback={() => {
        form.setValue('minPrice', minPrice)
        form.setValue('maxPrice', maxPrice)
      }}
    >
      <FormProvider {...form}>
        <Row gutter={18} className="provider-filter__pricing-filter__range">
          <Col span={12}>
            <FormItem
              name="minPrice"
              label={<FormattedMessage id="providersFilter.priceRangeSegment.from" />}
              className="provider-filter__form-input"
            >
              <Input
                name="minPrice"
                rules={{
                  required: intl.formatMessage({ id: 'errors.empty' }),
                  validate: {
                    isPositive: greaterThan(0, intl.formatMessage({ id: 'errors.invalid' })),
                  },
                }}
                min={0}
                autoFocus={true}
                size="large"
                defaultValue={minPrice}
                suffix={currencySymbol}
                className="ant-input--with-border-radius"
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              name="maxPrice"
              label={<FormattedMessage id="providersFilter.priceRangeSegment.to" />}
              className="provider-filter__form-input"
            >
              <Input
                name="maxPrice"
                min={1}
                rules={{
                  deps: ['minPrice'],
                  required: intl.formatMessage({ id: 'errors.empty' }),
                  validate: {
                    isPositive: greaterThan(0, intl.formatMessage({ id: 'errors.invalid' })),
                    isRangeValid: greaterThanField(form, 'minPrice', intl.formatMessage({ id: 'errors.invalid' })),
                  },
                }}
                size="large"
                defaultValue={maxPrice}
                suffix={currencySymbol}
                className="ant-input--with-border-radius"
              />
            </FormItem>
          </Col>
        </Row>
      </FormProvider>
    </FilterButtonWithOverlay>
  )
}

export default PricingFilter
