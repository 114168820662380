import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import { useIntl } from 'react-intl'
import camelCase from 'lodash/camelCase'
import { useRouter } from 'next/router'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import classNames from 'classnames'

import { setProvidersFilter } from 'store/modules/ProvidersFilter'
import { applyFilters, trackFilterUsage } from 'helpers/filters'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import ChooseList from 'components/ChooseList'
import FormItem from 'components/Form/Item'
import InputNumber from 'components/Form/InputNumber'
import { setPreselectedFormData } from 'hooks/preselectedFormData'

import FilterButtonWithOverlay from './components/FilterButtonWithOverlay'

import './shared.less'
import './GuestFilter.less'

const GuestFilter = (props) => {
  const {
    seatingTypeFilter,
  } = props

  const intl = useIntl()
  const dispatch = useDispatch()
  const {
    guestCountValue,
    seatingOptions,
    seatingValue,
  } = useSelector((state) => ({
    guestCountValue: state.providersFilter.filters.guestCount,
    seatingOptions: state.providersFilter.seatings,
    seatingValue: state.providersFilter.filters.seatingPlans,
  }), shallowEqual)

  const form = useForm({ mode: 'onChange' })
  const router = useRouter()
  const seatingPlans = form.watch('seatingPlans')

  const applyFiltersHandler = async () => {
    const isValid = await form.trigger()

    if (!isValid) { return false }

    const updateObject = { ...form.getValues() }

    trackFilterUsage(updateObject)
    dispatch(setProvidersFilter(updateObject, (nextState) => {
      applyFilters(router)

      if (nextState.filters.guestCount) {
        setPreselectedFormData({ numberOfPersons: nextState.filters.guestCount })
      }
      if (nextState.filters.seatingPlans) {
        // set one String to roomType to not break multi step inquiry form pre-filling
        setPreselectedFormData({ roomType: nextState.filters.seatingPlans[0] })
      }
    }))
  }

  const resetFiltersHandler = () => {
    const emptyFilters = {
      seatingPlans: [],
      guestCount: '',
    }

    dispatch(setProvidersFilter(emptyFilters, () => {
      applyFilters(router)
      setPreselectedFormData(emptyFilters)
    }))
  }

  const guestFilterButton = [{
    placeholder: <FormattedMessage id="common.guests" />,
    badge: Array.isArray(seatingValue) && seatingValue.length > 1 ? `+${seatingValue.length - 1}` : '',
    value: (!!guestCountValue || !!seatingValue.length) && (
      <>
        {guestCountValue} <FormattedMessage id="common.guests" />
        {guestCountValue && seatingValue[0] && ', '}
        {seatingValue[0] && (
          <FormattedMessage id={
            `shared.roomType.${camelCase(Array.isArray(seatingValue) ? seatingValue[0] : seatingValue)}`
          } />
        )}
      </>
    ),
  }]

  return (
    <FilterButtonWithOverlay
      applyHandler={applyFiltersHandler}
      resetHandler={resetFiltersHandler}
      className={classNames('provider-filter__guests', {
        'provider-filter__guests--with-seating-type': seatingTypeFilter,
      })}
      buttons={guestFilterButton}
      onOpenCallback={() => {
        form.setValue('guestCount', guestCountValue)
        form.setValue('seatingPlans', seatingValue)
      }}
    >
      <FormProvider {...form}>
        <Row gutter={12}>
          <Col span={seatingTypeFilter ? 12 : 24}>
            <FormItem
              name="guestCount"
              label={<FormattedMessage id="newProviderFilters.guestCount" />}
              className="provider-filter__guests__form-item provider-filter__form-input"
            >
              <InputNumber
                name="guestCount"
                autoFocus
                size="large"
                className="provider-filter__guests__count-input ant-input--with-border-radius"
                defaultValue={guestCountValue}
                placeholder={intl.formatMessage({ id: 'common.exampleValue' }, { value: '50' })}
                rules={{
                  deps: ['seatingPlans'],
                  required: seatingPlans?.length && intl.formatMessage({ id: 'errors.empty' }),
                  validate: (value) => !isNaN(value) || intl.formatMessage({ id: 'errors.invalid' }),
                }}
              />
            </FormItem>
          </Col>

          {seatingTypeFilter && (
            <Col span={12}>
              <Controller
                name="seatingPlans"
                defaultValue={seatingValue}
                render={({
                  field: { onChange, value },
                }) => (
                  <ChooseList
                    label={<FormattedMessage id="newProviderFilters.roomType" />}
                    list={seatingOptions}
                    className="provider-filter__guests__seating"
                    initialValue={value || []}
                    multiple={true}
                    selectCallback={onChange}
                    renderItem={useCallback((item) => (
                      <>
                        <Icon name={`seating-${item.id}`} />
                        {' '}
                        {item.text}
                      </>
                    ), [])}
                  />
                )}
              />
            </Col>
          )}
        </Row>
      </FormProvider>
    </FilterButtonWithOverlay>
  )
}

GuestFilter.propTypes = {
  seatingTypeFilter: PropTypes.bool,
}

export default GuestFilter
