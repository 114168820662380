import React, { useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Row, Col } from 'antd'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'

import {
  initialBounds,
  setProvidersFilter,
} from 'store/modules/ProvidersFilter'
import { applyFilters, trackFilterUsage } from 'helpers/filters'

import { setPreselectedFormData } from 'hooks/preselectedFormData'
import ChooseList from 'components/ChooseList'
import FormattedMessage from 'components/FormattedMessage'

import FilterButtonWithOverlay from './components/FilterButtonWithOverlay'
import GeoLocationSearch from './GeoLocationSearch'

import './CityOccasionFilters.less'

const CityOccasionFilters = (props) => {
  const {
    showCityFilter,
    showOccasionFilter,
    showSupplierTypeFilter,
  } = props

  const intl = useIntl()
  const router = useRouter()
  const dispatch = useDispatch()
  const {
    areaFilterList,
    eventCategories,
    supplierTypes,
    selectedCity,
    selectedArea,
    selectedOccasion,
    selectedSupplierType,
    isGeoLocationSearchEnabled,
  } = useSelector(state => ({
    areaFilterList: state.providersFilter.areasForDropdown,
    eventCategories: state.providersFilter.eventCategories,
    supplierTypes: state.providersFilter.supplierTypes || [],
    selectedCity: state.providersFilter.filters.cityFilter,
    selectedArea: state.providersFilter.filters.cityFilter || state.providersFilter.filters.mapboxId,
    selectedOccasion: state.providersFilter.filters.occasionFilter,
    selectedSupplierType: state.providersFilter.filters.supplierType?.[0],
    isGeoLocationSearchEnabled: state.headerData.experiments.geoLocationSearch,
  }), shallowEqual)

  if (selectedArea) {
    areaFilterList.sort((a, b) => {
      if (a.id === selectedArea) { return -1 }
      if (b.id === selectedArea) { return 1 }

      return 0
    })
  }

  const [filters, setFilters] = useState({
    cityFilter: selectedCity,
    occasionFilter: selectedOccasion,
    property: selectedSupplierType,
  })

  const inputRefs = {
    city: useRef(),
    occasion: useRef(),
    locationType: useRef(),
    supplierType: useRef(),
  }

  const [clickedFilter, setClickedFilter] = useState()

  useEffect(() => {
    if (inputRefs[clickedFilter]?.current) {
      inputRefs[clickedFilter].current.input.focus()
    }
  }, [clickedFilter])

  const selectMapboxPlaceFilter = useCallback((mapboxId, bounds = initialBounds) => {
    setFilters({
      ...filters,
      cityFilter: null,
      mapboxId,
      bounds,
    })
  }, [setFilters, filters])
  const selectCityFilter = useCallback((cityFilter) => {
    setFilters({
      ...filters,
      bounds: initialBounds,
      cityFilter,
    })
  }, [setFilters, filters])

  const selectFilterCallback = useCallback((filterName) => (selectedValue) => {
    setFilters({
      ...filters,
      [filterName]: selectedValue,
    })
  }, [setFilters, filters])
  const selectOccasionFilter = useCallback(selectFilterCallback('occasionFilter'), [selectFilterCallback])
  const selectSuppliertypeFilter = useCallback(selectFilterCallback('supplierType'), [selectFilterCallback])

  const applyFiltersHandler = () => {
    const clonedFilters = { ...filters }

    trackFilterUsage(clonedFilters)
    dispatch(setProvidersFilter(clonedFilters, () => {
      applyFilters(router)
      setPreselectedFormData({ eventCategory: filters.occasionFilter })
    }))
  }

  useEffect(() => {
    setFilters({
      cityFilter: selectedCity,
      occasionFilter: selectedOccasion,
      property: selectedSupplierType,
    })
  }, [selectedOccasion, selectedSupplierType, selectedCity])

  const resetFiltersHandler = () => {
    const emptyFilters = {
      cityFilter: null,
      occasionFilter: showOccasionFilter ? null : selectedOccasion,
      locationTypes: [],
      supplierType: null,
      bounds: initialBounds,
    }

    setFilters(emptyFilters)
    dispatch(setProvidersFilter(emptyFilters, () => {
      applyFilters(router)
      setPreselectedFormData({ eventCategory: '' })
    }))
  }

  const selectedAreaText = areaFilterList.find(item => item.id === selectedArea)?.text
  const selectedOccasionText = eventCategories.find(item => item.id === selectedOccasion)?.text
  const selectedSupplierTypeText = supplierTypes.find(item => item.id === selectedSupplierType)?.text

  const buttons = []

  if (showCityFilter) {
    buttons.push({
      value: selectedAreaText,
      placeholder: isGeoLocationSearchEnabled ? (
        <FormattedMessage id="newProviderFilters.place" />
      ) : (
        <FormattedMessage id="newProviderFilters.city" />
      ),
      onClick: () => { setClickedFilter('city') },
    })
  }

  if (showOccasionFilter) {
    buttons.push({
      value: selectedOccasionText,
      placeholder: <FormattedMessage id="newProviderFilters.occasion" />,
      onClick: () => { setClickedFilter('occasion') },
    })
  }

  if (showSupplierTypeFilter) {
    buttons.push({
      value: selectedSupplierTypeText,
      placeholder: <FormattedMessage id="newProviderFilters.supplierType" />,
      onClick: () => { setClickedFilter('supplierType') },
    })
  }

  const overlayColumnCount = [
    showCityFilter,
    showOccasionFilter,
    showSupplierTypeFilter,
  ].filter(Boolean).length || 1
  const overlayColumnSpan = 24 / overlayColumnCount

  const dontPropagateEnterEvent = (event) => {
    if (event.key.toLowerCase() === 'enter') {
      event.stopPropagation()
    }
  }

  return (
    <FilterButtonWithOverlay
      applyHandler={applyFiltersHandler}
      applyButtonProps={{ tabIndex: 4 }}
      resetHandler={resetFiltersHandler}
      resetButtonProps={{ tabIndex: 5 }}
      className={classNames(
        'provider-filter__city-occasion-providertype',
        `provider-filter__city-occasion-providertype--col-${overlayColumnCount}`,
      )}
      buttons={buttons}
    >
      <Row gutter={16} type="flex" className="provider-filter__city-occasion-providertype__content">
        {showCityFilter && (
          <Col span={overlayColumnSpan}>
            <GeoLocationSearch
              ref={inputRefs.city}
              selectCityCallback={selectCityFilter}
              selectedArea={selectedArea}
              areaFilterList={areaFilterList}
              selectMapboxPlaceFilter={selectMapboxPlaceFilter}
              filterInputProps={{
                autoFocus: clickedFilter === 'city',
                onKeyDown: dontPropagateEnterEvent,
              }}
            />
          </Col>
        )}

        {showOccasionFilter && (
          <Col span={overlayColumnSpan}>
            <ChooseList
              label={<FormattedMessage id="newProviderFilters.occasion" />}
              filterable={true}
              filterInputProps={{
                autoFocus: clickedFilter === 'occasion',
                placeholder: intl.formatMessage({ id: 'newProviderFilters.occasion.placeholder' }),
                tabIndex: 2,
                onKeyDown: dontPropagateEnterEvent,
                ref: inputRefs.occasion,
              }}
              list={eventCategories}
              initialValue={selectedOccasion}
              selectCallback={selectOccasionFilter}
            />
          </Col>
        )}

        {showSupplierTypeFilter && (
          <Col span={overlayColumnSpan}>
            <ChooseList
              label={<FormattedMessage id="newProviderFilters.supplierType" />}
              filterable={true}
              filterInputProps={{
                autoFocus: clickedFilter === 'supplierType',
                placeholder: intl.formatMessage({ id: 'newProviderFilters.supplierType.placeholder' }),
                tabIndex: 3,
                onKeyDown: dontPropagateEnterEvent,
                ref: inputRefs.supplierType,
              }}
              list={supplierTypes}
              initialValue={selectedSupplierType}
              selectCallback={selectSuppliertypeFilter}
            />
          </Col>
        )}
      </Row>
    </FilterButtonWithOverlay>
  )
}

CityOccasionFilters.defaultProps = {
  selectedOccasion: null,
}

CityOccasionFilters.propTypes = {
  showCityFilter: PropTypes.bool,
  showOccasionFilter: PropTypes.bool,
  showSupplierTypeFilter: PropTypes.bool,
}

export default CityOccasionFilters
