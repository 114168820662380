import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

const Inputs = (props) => {
  const {
    items,
    isHidden,
    colspan,
    inputComponent,
  } = props

  const Input = inputComponent

  return (
    <Row type="flex" gutter={16}>
      {items.map((item) => (
        <Col span={colspan} key={item.id}>
          <Input value={item.id} tabIndex={isHidden ? -1 : 0}>{item.text}</Input>
        </Col>
      ))}
    </Row>
  )
}

Inputs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  })).isRequired,
  isHidden: PropTypes.bool,
  colspan: PropTypes.number,
  inputComponent: PropTypes.func,
}

export default Inputs
