import React, { useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import Badge from 'components/Badge'
import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import MoreFiltersOverlay from './Overlay'

import '../shared.less'
import './index.less'

const MoreFilters = (props) => {
  const selectedMoreFiltersCount = useSelector((state) => ([
    !!state.providersFilter.filters.locationTraits?.length,
    !!state.providersFilter.filters.cateringOptions?.length,
    !!state.providersFilter.filters.roomSize,
    !!state.providersFilter.filters.locationTypes?.length,
    !!state.providersFilter.filters.technicalEquippings.length,
    !!state.providersFilter.filters.amenities.length,
    !!state.providersFilter.filters.hybrid,
    !!state.providersFilter.filters.hotelTypes.length,
    !!state.providersFilter.filters.hotelRooms,
    !!state.providersFilter.filters.visibility?.length,
  ].filter(Boolean).length))

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        type="default"
        className={classNames(
          'ant-btn--brand-font',
          'provider-filter__button',
          'provider-filter__more-filters__button',
          {
            'provider-filter__button--selected': selectedMoreFiltersCount > 0,
          },
        )}
        onClick={() => setIsOpen(true)}
      >
        <Icon name="filter" />

        <span className="provider-filter__more-filters__button__text">
          <FormattedMessage id="newProviderFilters.moreFilters.button" />
        </span>

        {selectedMoreFiltersCount > 0 && (
          <Badge>{selectedMoreFiltersCount}</Badge>
        )}
      </Button>

      {isOpen && (
        <MoreFiltersOverlay
          closeHandler={() => setIsOpen(false)}
          {...props}
        />
      )}
    </>
  )
}

export default MoreFilters
