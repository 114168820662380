import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'

import { geoLocationSearch as geoLocationSearchActionCreator } from 'store/modules/ProvidersFilter'

import Icon from 'components/Icon'
import ChooseList from 'components/ChooseList'
import FormattedMessage from 'components/FormattedMessage'

import { isMsie } from 'helpers/browser'

const GeoLocationSearch = React.forwardRef((props, inputRef) => {
  const {
    selectCityCallback,
    selectMapboxPlaceFilter,
    filterInputProps,
    selectedArea,
    areaFilterList,
  } = props
  const isGeoLocationSearchEnabled = !isMsie()
  const [isLoading, setLoading] = useState()
  const intl = useIntl()
  const dispatch = useDispatch()
  const geoLocationSearch = bindActionCreators(geoLocationSearchActionCreator, dispatch)
  const translationKeyPrefix = isGeoLocationSearchEnabled ? 'newProviderFilters.place' : 'newProviderFilters.city'

  const select = useCallback(async (selectedValue) => {
    if (!selectedValue) {
      selectMapboxPlaceFilter()
      selectCityCallback()

      return
    }

    const selectedArea = areaFilterList.find(place => place.id === selectedValue)

    if (selectedArea.id === selectedArea.mapboxId) {
      let bbox = selectedArea.bbox

      if (!bbox) {
        const WebMercatorViewport = (await import('react-map-gl')).WebMercatorViewport

        bbox = new WebMercatorViewport({
          longitude: selectedArea.center[0],
          latitude: selectedArea.center[1],
          zoom: 6,
        }).getBounds().flat()
      }

      return selectMapboxPlaceFilter(selectedArea.mapboxId, {
        west: bbox[0],
        south: bbox[1],
        east: bbox[2],
        north: bbox[3],
      })
    }

    selectCityCallback(selectedValue)
  }, [selectCityCallback, selectMapboxPlaceFilter, areaFilterList])

  return (
    <ChooseList
      label={<FormattedMessage id={translationKeyPrefix} />}
      filterable={true}
      filterInputProps={{
        prefix: isLoading ? <Icon name="refresh" className="icon-spin" /> : <Icon name="marker" />,
        placeholder: intl.formatMessage({ id: `${translationKeyPrefix}.placeholder` }),
        tabIndex: 1,
        ref: inputRef,
        onChange: isGeoLocationSearchEnabled ? (query) => {
          if (query) {
            geoLocationSearch({ query }, (isLoading) => setLoading(isLoading))
          }
        } : undefined,
        ...filterInputProps,
      }}
      filterCallback={useCallback((city, query, defaultFilterCallback) => {
        if (query) {
          return city.isResult || defaultFilterCallback(city)
        }

        return city.isMain || selectedArea === city.id
      }, [selectedArea, geoLocationSearch])}
      list={areaFilterList}
      initialValue={selectedArea}
      selectCallback={select}
      prependList={useCallback(({ filterQuery }) => {
        if (filterQuery) {
          return null
        }

        return (
          <div className="provider-filter__city-occasion-providertype__content__city-hint">
            <FormattedMessage id={`${translationKeyPrefix}.hint.search`} />

            <div className="provider-filter__city-occasion-providertype__content__city-hint__or">
              <span className="provider-filter__city-occasion-providertype__content__city-hint__or__inner">
                <FormattedMessage id="common.or" />
              </span>
            </div>

            <FormattedMessage id="newProviderFilters.city.hint.topCities" />
          </div>
        )
      }, [])}
    />
  )
})

GeoLocationSearch.propTypes = {
  selectCityCallback: PropTypes.func.isRequired,
  selectMapboxPlaceFilter: PropTypes.func.isRequired,
  filterInputProps: PropTypes.object.isRequired,
  areaFilterList: PropTypes.array.isRequired,
  selectedArea: PropTypes.string,
}

export default GeoLocationSearch
