import React from 'react'
import PropTypes from 'prop-types'

import ToggleFilterSection from 'apps/FilterPage/ProviderFilter/components/ToggleFilterSection'
import CityOccasionFilters from './CityOccasionFilters'
import GuestFilter from './GuestFilter'
import PricingFilter from './PricingFilter'
import MoreFilters from './MoreFilters'
import AvailabilityFilter from './AvailabilityFilter'
import ToggleMap from './ToggleMap'

import { isMsie } from 'helpers/browser'

import '../shared.less'
import './index.less'

const DesktopFilters = (props) => {
  const { displayedFilters } = props

  const cityOccasionFilters = {
    showCityFilter: displayedFilters.cityFilter,
    showOccasionFilter: displayedFilters.occasionFilter,
    showSupplierTypeFilter: displayedFilters.supplierTypeFilter,
  }

  const moreFilters = {
    sitingFilter: displayedFilters.sitingFilter,
    roomSizeFilter: displayedFilters.roomSizeFilter,
    cateringOptionsFilter: displayedFilters.cateringOptionsFilter,
    technicalEquippingsFilter: displayedFilters.technicalEquippingsFilter,
    locationTypeFilter: displayedFilters.locationTypeFilter,
    amenitiesFilter: displayedFilters.amenitiesFilter,
    hybridFilter: displayedFilters.hybridFilter,
    hotelFilter: displayedFilters.hotelFilter,
    visibilityFilter: displayedFilters.visibilityFilter,
  }

  const showMoreFilters = Object.values(moreFilters).some(Boolean)
  const showCityOccasionFilters = Object.values(cityOccasionFilters).some(Boolean)

  return (
    <div className="provider-filter provider-filter--desktop">
      {showCityOccasionFilters && (
        <div className="provider-filter__col">
          <CityOccasionFilters {...cityOccasionFilters} />
        </div>
      )}

      {displayedFilters.guestFilter && (
        <div className="provider-filter__col">
          <GuestFilter seatingTypeFilter={displayedFilters.seatingTypeFilter} />
        </div>
      )}

      {displayedFilters.availabilityFilter && (
        <div className="provider-filter__col">
          <AvailabilityFilter />
        </div>
      )}

      {displayedFilters.pricingFilter && (
        <div className="provider-filter__col">
          <PricingFilter />
        </div>
      )}

      {showMoreFilters && (
        <div className="provider-filter__col">
          <MoreFilters displayedFilters={moreFilters} />
        </div>
      )}

      {displayedFilters.switchEventsIdeas && (
        <div className="provider-filter__col">
          <ToggleFilterSection offSection="events" onSection="events_ideas" />
        </div>
      )}

      {displayedFilters.switchMeetingLocations && (
        <div className="provider-filter__col">
          <ToggleFilterSection onSection="meeting" offSection="meeting_locations" />
        </div>
      )}

      {displayedFilters.switchConferenceCongressIdeas && (
        <div className="provider-filter__col">
          <ToggleFilterSection offSection="conference_congress" onSection="conference_congress_ideas" />
        </div>
      )}

      {displayedFilters.mapFilter && !isMsie() && (
        <div className="provider-filter__col provider-filter__col--extracted-filter-column">
          <ToggleMap />
        </div>
      )}
    </div>
  )
}

DesktopFilters.propTypes = {
  displayedFilters: PropTypes.shape({
    amenitiesFilter: PropTypes.bool,
    availabilityFilter: PropTypes.bool,
    cityFilter: PropTypes.bool,
    guestFilter: PropTypes.bool,
    seatingTypeFilter: PropTypes.bool,
    hybridFilter: PropTypes.bool,
    locationTypeFilter: PropTypes.bool,
    mapFilter: PropTypes.bool,
    occasionFilter: PropTypes.bool,
    pricingFilter: PropTypes.bool,
    roomSizeFilter: PropTypes.bool,
    sitingFilter: PropTypes.bool,
    cateringOptionsFilter: PropTypes.bool,
    supplierTypeFilter: PropTypes.bool,
    technicalEquippingsFilter: PropTypes.bool,
    hotelFilter: PropTypes.bool,
    switchEventsIdeas: PropTypes.bool,
    switchMeetingLocations: PropTypes.bool,
    switchConferenceCongressIdeas: PropTypes.bool,
    visibilityFilter: PropTypes.bool,
  }).isRequired,
}

export default DesktopFilters
