import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useRouter } from 'next/router'
import { useForm, FormProvider } from 'react-hook-form'

import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'
import Layer from 'components/Layer'
import Badge from 'components/Badge'
import Icon from 'components/Icon'

import HotelRoom from './HotelRoom'

import { setProvidersFilter } from 'store/modules/ProvidersFilter'
import { applyFilters, trackFilterUsage } from 'helpers/filters'

import MoreFiltersItem from './Item'

import './Overlay.less'

const MoreFiltersOverlay = (props) => {
  const {
    closeHandler,
    displayedFilters,
  } = props

  const router = useRouter()
  const dispatch = useDispatch()
  const {
    amenities,
    amenityValues,
    locationTypes,
    locationTypeValues,
    locationTraitOptions,
    locationTraitValues,
    roomSizeOptions,
    roomSizeValue,
    cateringOptions,
    cateringValues,
    technicalEquippings,
    technicalEquippingValues,
    hotelTypes,
    hotelTypesValue,
    hotelRooms,
    hybrid,
    visibilities,
    visibilityValues,
  } = useSelector(state => ({
    amenities: state.providersFilter.amenities,
    amenityValues: state.providersFilter.filters.amenities,
    locationTypes: state.providersFilter.locationTypes,
    locationTypeValues: state.providersFilter.filters.locationTypes,
    locationTraitOptions: state.providersFilter.locationTraits,
    locationTraitValues: state.providersFilter.filters.locationTraits,
    roomSizeOptions: state.providersFilter.roomSizes,
    roomSizeValue: state.providersFilter.filters.roomSize,
    cateringOptions: state.providersFilter.cateringOptions,
    cateringValues: state.providersFilter.filters.cateringOptions,
    technicalEquippings: state.providersFilter.technicalEquippings,
    technicalEquippingValues: state.providersFilter.filters.technicalEquippings,
    hybrid: state.providersFilter.filters.hybrid,
    hotelTypes: state.providersFilter.hotelTypes,
    hotelTypesValue: state.providersFilter.filters.hotelTypes,
    hotelRooms: state.providersFilter.filters.hotelRooms,
    visibilities: state.providersFilter.visibilities,
    visibilityValues: state.providersFilter.filters.visibility,
  }), shallowEqual)

  const form = useForm({
    mode: 'onChange',
    shouldUnregister: true,
  })
  const applyHandler = () => {
    const values = form.getValues()
    const trackedValues = { ...form.getValues() }

    if (displayedFilters.hybridFilter) {
      values.hybrid = values.hybrid.some(Boolean)
      trackedValues.hybrid = values.hybrid ? '1' : '0'
    }

    trackFilterUsage(trackedValues)
    dispatch(setProvidersFilter(values, () => {
      applyFilters(router)
      closeHandler()
    }))
  }

  const resetHandler = () => {
    const defaultFilters = {
      cateringOptions: [],
      locationTraits: [],
      locationTypes: [],
      roomSize: '',
      technicalEquippings: [],
      amenities: [],
      hotelTypes: [],
      hotelRooms: null,
      visibility: [],
    }

    if (displayedFilters.hybridFilter) {
      defaultFilters.hybrid = false
    }

    dispatch(setProvidersFilter(defaultFilters, () => {
      applyFilters(router)
      closeHandler()
    }))
  }

  const optionalRoomSizeOptions = [
    { id: '', text: <FormattedMessage id="providersFilter.noSelection" /> },
    ...roomSizeOptions,
  ]

  return (
    <Layer
      maxWidth={780}
      verticalAlignMiddle={false}
      onClose={closeHandler}
      className="provider-filter__more-filters__overlay"
      footer={(
        <div className="provider-filter__more-filters__overlay__footer">
          <Button
            type="link"
            className="ant-btn--brand-font ant-btn--with-border-radius-medium"
            translationKey="newProviderFilters.reset"
            onClick={resetHandler}
          />

          <Button
            type="primary"
            className="ant-btn--brand-font ant-btn--with-border-radius-medium"
            translationKey="newProviderFilters.apply"
            onClick={applyHandler}
          />
        </div>
      )}
    >
      <div className="provider-filter__more-filters__overlay__header">
        <FormattedMessage id="newProviderFilters.moreFilters.overlay.header" />
      </div>

      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(applyHandler)}
          className="provider-filter__more-filters__overlay__content"
        >
          {displayedFilters.visibilityFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.visibility.label" />}
              name="visibility"
              colspan={8}
              items={visibilities}
              selectedValues={visibilityValues}
              multiple={true}
            />
          )}

          {displayedFilters.roomSizeFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.roomSize.label" />}
              name="roomSize"
              colspan={8}
              items={optionalRoomSizeOptions}
              selectedValues={roomSizeValue}
            />
          )}

          {displayedFilters.hotelFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.hotel.label" />}
              name="hotelTypes"
              colspan={8}
              items={hotelTypes}
              selectedValues={hotelTypesValue}
              multiple={true}
            >
              <HotelRoom form={form} initialValue={hotelRooms} />
            </MoreFiltersItem>
          )}

          {displayedFilters.technicalEquippingsFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.technicalEquippings.label" />}
              name="technicalEquippings"
              colspan={8}
              items={technicalEquippings}
              selectedValues={technicalEquippingValues}
              multiple={true}
            />
          )}

          {displayedFilters.cateringOptionsFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.cateringOptions.label" />}
              name="cateringOptions"
              colspan={8}
              items={cateringOptions}
              selectedValues={cateringValues}
              multiple={true}
            />
          )}

          {displayedFilters.locationTypeFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.locationTypes.label" />}
              name="locationTypes"
              colspan={8}
              items={locationTypes}
              selectedValues={locationTypeValues}
              multiple={true}
            />
          )}

          {displayedFilters.amenitiesFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.amenities.label" />}
              name="amenities"
              colspan={8}
              items={amenities}
              selectedValues={amenityValues}
              multiple={true}
            />
          )}

          {displayedFilters.sitingFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.locationTraits.label" />}
              name="locationTraits"
              colspan={8}
              items={locationTraitOptions}
              selectedValues={locationTraitValues}
              multiple={true}
            />
          )}

          {displayedFilters.hybridFilter && (
            <MoreFiltersItem
              label={<FormattedMessage id="newProviderFilters.moreFilters.overlay.features.headline" />}
              name="hybrid"
              colspan={24}
              selectedValues={hybrid ? ['hybrid'] : []}
              multiple={true}
              items={[{
                id: 'hybrid',
                text: (
                  <div className="provider-filter__more-filters__overlay__content__features">
                    <FormattedMessage id="newProviderFilters.moreFilters.overlay.hybridFilter.label" />

                    <Badge type="peacock-color">
                      <Icon name="camera" />
                    </Badge>
                  </div>
                ),
              }]}
            />
          )}
        </form>
      </FormProvider>
    </Layer>
  )
}

MoreFiltersOverlay.defaultProps = {
  closeHandler: () => {},
}

MoreFiltersOverlay.propTypes = {
  closeHandler: PropTypes.func,
  displayedFilters: PropTypes.shape({
    sitingFilter: PropTypes.bool,
    roomSizeFilter: PropTypes.bool,
    cateringOptionsFilter: PropTypes.bool,
    technicalEquippingsFilter: PropTypes.bool,
    amenitiesFilter: PropTypes.bool,
    hybridFilter: PropTypes.bool,
    hotelFilter: PropTypes.bool,
    locationTypeFilter: PropTypes.bool,
    visibilityFilter: PropTypes.bool,
  }).isRequired,
}

export default MoreFiltersOverlay
