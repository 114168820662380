import React from 'react'
import { Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import FormattedMessage from 'components/FormattedMessage'

import { setDesktopMapExpanded } from 'store/modules/ProvidersFilter'
import { pushDataLayer } from 'helpers/googleAnalytics'

const ToggleMap = () => {
  const isDesktopMapExpanded = useSelector(state => state.providersFilter.isDesktopMapExpanded)
  const dispatch = useDispatch()
  const changeHandler = (checked) => {
    dispatch(setDesktopMapExpanded(checked))

    pushDataLayer({
      event: 'googleAnalyticsGenericEvent',
      eventCategory: 'filtersPageDesktopMap',
      eventAction: checked ? 'enabled' : 'disabled',
    })
  }

  const switchId = 'toggle-map-filter'

  return (
    <div className="provider-filter__switch-filter">
      <Switch
        checked={isDesktopMapExpanded}
        onChange={changeHandler}
        id={switchId}
      />

      <label
        className="provider-filter__switch-filter__label"
        htmlFor={switchId}
      >
        <FormattedMessage id="providerFilters.toggleMap" />
      </label>
    </div>
  )
}

export default ToggleMap
