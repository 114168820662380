import React from 'react'
import PropTypes from 'prop-types'

import FormItem from 'components/Form/Item'
import CheckboxGroup from 'components/Form/CheckboxGroup'
import RadioGroup from 'components/Form/RadioGroup'

import Inputs from './Inputs'

import './Item.less'

const Item = (props) => {
  const {
    name,
    children = null,
    label,
    colspan,
    items,
    multiple,
    selectedValues,
  } = props

  const Input = multiple ? CheckboxGroup : RadioGroup

  return (
    <FormItem
      className="provider-filter__more-filters__item"
      label={label}
    >
      <Input
        name={name}
        defaultValue={selectedValues}
      >
        <div className="provider-filter__more-filters__item__content">
          <Inputs
            inputComponent={Input.Item}
            colspan={colspan}
            items={items}
          />
        </div>
      </Input>

      {children}
    </FormItem>
  )
}

Item.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  colspan: PropTypes.number,
  multiple: PropTypes.bool,
  selectedValues: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  })),
}

export default Item
