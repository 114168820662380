import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './List.less'

const List = ({ list, onSelectCallback, selectedItems, renderItem }) => (
  <ul className="choose-list__list">
    {list.map(item => {
      const isSelected = selectedItems.includes(item.id)

      return (
        <li
          key={item.id}
          className={classNames('choose-list__list__item', {
            'choose-list__list__item--selected': isSelected,
          })}
          onClick={() => onSelectCallback(item.id)}
        >
          {renderItem(item, isSelected)}
        </li>
      )
    })}
  </ul>
)

List.defaultProps = {
  renderItem: (item) => item.text,
}

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  onSelectCallback: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  renderItem: PropTypes.func.isRequired,
}

export default List
