import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FormattedMessage from 'components/FormattedMessage'
import InputNumber from 'components/Form/InputNumber'

import './index.less'

const HotelRoom = ({ initialValue }) => (
  <>
    <div className="provider-filter__more-filters__hotel-rooms__label">
      <FormattedMessage id="newProviderFilters.moreFilters.overlay.hotelRooms.label" />
    </div>

    <InputNumber
      name="hotelRooms"
      defaultValue={initialValue}
      min={0}
      placeholder={0}
      className={classNames(
        'ant-input--with-border-radius-large',
        'provider-filter__more-filters__hotel-rooms__input',
      )}
    />
  </>
)

HotelRoom.propTypes = {
  initialValue: PropTypes.number,
}

export default HotelRoom
